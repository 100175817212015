import './inkfree.ttf';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="resto">
          <a href="https://volta.viruburger.ee">
            <img src="/static/volta.png" />
            <div className="resto-title">
              <span>
                Viru Burger x Volta Resto
              </span>
            </div>
          </a>
        </div>
        <div className="resto">
          <a href="https://mustamae.viruburger.ee">
            <img src="/static/musta.png" />
            <div className="resto-title">
              <span>
                Viru Burger x Mustamäe Keskus
              </span>
            </div>
          </a>
        </div>
        <div className="resto">
          <a href="https://tartu.viruburger.ee">
            <img src="/static/tartu.png" />
            <div className="resto-title">
              <span>
                Viru Burger x Tartu
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
